'use client'

import { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Icon } from '@shc/ui'
import { useSearchParams } from 'next/navigation'
import slugify from '@sindresorhus/slugify'

import {
  requestBrowserLocation,
  type BrowserLocation,
  type GeoData,
} from '@lib/utilities/location-utilities'
import { useSharedSearchContext } from '@app/(main)/search/_contexts/search-context-wrapper'
import Autocomplete, { type AutocompleteProps } from '@components/autocomplete'
import useGeocode from '@hooks/useGeocode'

interface SearchLocationAutocompleteProps
  extends Omit<AutocompleteProps, 'value' | 'label' | 'children'> {}

export default function SearchLocationAutocomplete(
  props: Readonly<SearchLocationAutocompleteProps>
) {
  const { fetchLocations, reverseGeocode, isLoaded: isGoogleApiLoaded } = useGeocode()
  const searchParams = useSearchParams()
  const { selectedLocation, setSelectedLocation } = useSharedSearchContext()
  const [filteredLocations, setFilteredLocations] = useState<GeoData[]>([])
  const [searchLocationError, setSearchLocationError] = useState<string | null>(null)
  const [initialLocationQuery] = useState<string | null>(searchParams?.get('l')?.trim() ?? null)
  const [searchLocationQuery] = useState<string | null>(initialLocationQuery)

  // INIT
  // This useEffect hook runs only once when `initialLocationQuery` and `isGoogleApiLoaded` are set,
  // it grabs the "l" value from the URL and fetches the location data from the Google API
  useEffect(() => {
    if (initialLocationQuery && initialLocationQuery === searchLocationQuery && isGoogleApiLoaded) {
      fetchLocations(searchLocationQuery ?? '')
        .then((fetchedLocations) => {
          setFilteredLocations(fetchedLocations.locations)
          updateSelectedAndUrl(fetchedLocations.locations?.[0], initialLocationQuery)
        })
        .catch((error) => {
          setSearchLocationError(error.message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLocationQuery, isGoogleApiLoaded])

  const updateURLWithLocation = (locQuery: string | null) => {
    const url = new URL(window.location.href)

    if (locQuery) {
      url.searchParams.set('l', locQuery)
    } else {
      url.searchParams.delete('l')
    }
    window.history.replaceState(null, '', url.toString())
  }

  /**
   * Every time we set a new selectedLocation, also update the URL (if it's diffenerent than current)
   */
  const updateSelectedAndUrl = (selectedLocation: GeoData | null, fallbackValue: string | null) => {
    const newLocationQuery = selectedLocation?.formattedAddress ?? fallbackValue ?? null
    setSelectedLocation(selectedLocation)
    updateURLWithLocation(newLocationQuery)
  }

  // called when a user SELECTS an option in the combobox, hits enter or tabs out of the field
  async function handleLocationSelect(value: string) {
    if (value.toLowerCase() === 'current location') {
      await handleCurrentLocation()
    } else {
      // Attempt to find the selected location from filteredLocations
      const matchedLocation = filteredLocations.find(
        (location: GeoData) => location.formattedAddress === value
      )

      if (matchedLocation) {
        updateSelectedAndUrl(matchedLocation, value)
      } else {
        updateSelectedAndUrl(filteredLocations?.[0], value)
      }
    }
  }

  // called when user selects "Current location" option
  async function handleCurrentLocation() {
    // Request permission and get the user's current location
    const browserLocation: BrowserLocation = await requestBrowserLocation()
    const { status, latitude, longitude } = browserLocation

    // Check for errors
    let errorMessage = null
    if (status === 'denied') {
      errorMessage = 'Enable location permissions to continue.'
    } else if (!isGoogleApiLoaded) {
      errorMessage = 'Location service not available, please try again later.'
    } else if (latitude == null || longitude == null || isNaN(latitude) || isNaN(longitude)) {
      errorMessage = 'Could not get your current location.'
    }

    // Handle errors
    if (errorMessage) {
      setSearchLocationError(errorMessage)
      return
    }

    // Browser only returns the lat/long. Use those to get friendly location data from Google
    // the "!" (non-null assertion operators) are safe to use here because we've already
    // checked for nulls above with an early return
    const friendlyLocation = await reverseGeocode({ lat: latitude!, lng: longitude! })

    // Set the selectedLocation to the friendlyLocation at the context level
    if (friendlyLocation.length > 0 && friendlyLocation[0].formattedAddress) {
      updateSelectedAndUrl(friendlyLocation[0], 'Current location')
    }
  }

  // called when a user TYPES into the combobox
  // this will search google api and set filtered locations
  const handleLocationFieldInput = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    const locQuery = event?.target.value.trim()
    updateURLWithLocation(locQuery)
    setSearchLocationError(null)

    // handle empty query
    if (locQuery?.length === 0) {
      setFilteredLocations([])
      updateSelectedAndUrl(null, null)
      return
    }

    // fetch locations from google api
    if (locQuery && isGoogleApiLoaded) {
      fetchLocations(locQuery)
        .then((fetchedLocations) => {
          setFilteredLocations(fetchedLocations.locations)
          updateSelectedAndUrl(fetchedLocations.locations?.[0], locQuery)
        })
        .catch((error) => {
          setSearchLocationError(error.message)
        })
    } else {
      setFilteredLocations([])
    }
  }, 300)

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setSearchLocationError(null)
    event.target.select()
  }

  return (
    <Autocomplete
      {...props}
      type="text"
      autoFocus={false}
      autoComplete="off"
      autoCorrect="off"
      spellCheck={false}
      label="Location"
      hideLabel={true}
      placeholder="ZIP code, neighborhood or city"
      isError={!!searchLocationError}
      validationText={searchLocationError ?? ''}
      value={selectedLocation}
      query={props.disabled ? '' : searchLocationQuery ?? ''}
      getValueLabel={(value) => value?.formattedAddress ?? value}
      startAdornment={<Icon className="h-3.5" icon="location-dot" />}
      onFocus={handleOnFocus}
      onQuery={handleLocationFieldInput}
      onChange={handleLocationSelect}>
      <Autocomplete.Option key="currentLocation" value="Current location">
        <Icon icon="location-arrow" className="text-primary pr-2" />
        <span>Current location</span>
      </Autocomplete.Option>

      {filteredLocations.map((l: any) => {
        if (l.lat && l.lng) {
          return (
            <Autocomplete.Option key={slugify(l.formattedAddress)} value={l.formattedAddress}>
              {l.formattedAddress}
            </Autocomplete.Option>
          )
        }
      })}
    </Autocomplete>
  )
}
