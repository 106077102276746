'use client'

import {
  getSearchClient,
  getSearchDefaultClient,
  getSearchRouter,
  getRouterProps,
} from '@lib/search'
import { useRouter } from 'next/navigation'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { InstantSearch } from 'react-instantsearch'

type SearchPageWrapperType = {
  algoliaKey: string
  indexName: string
  basicRouting?: boolean
  noInitialResults?: boolean
  children: ReactNode
}

export default function SearchWrapper({
  algoliaKey,
  indexName,
  basicRouting = false,
  noInitialResults = false,
  children,
}: Readonly<SearchPageWrapperType>) {
  const router = useRouter()

  const searchClient = useMemo(() => {
    if (noInitialResults) {
      return getSearchClient(algoliaKey)
    } else {
      return getSearchDefaultClient(algoliaKey)
    }
  }, [algoliaKey, noInitialResults])

  const routing = useMemo(() => {
    if (basicRouting) {
      return getRouterProps(indexName)
    } else {
      return getSearchRouter(indexName, router)
    }
  }, [basicRouting, indexName, router])

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      future={{ preserveSharedStateOnUnmount: false }}
      routing={routing}>
      {children}
    </InstantSearch>
  )
}
