import { Skeleton } from '@shc/ui'

const WIDTHS = [40, 70, 70, 100]

function NavLinkLoading({ width }: Readonly<{ width: number }>) {
  return (
    <div className="first:-ml-5 first:xl:-ml-8 shrink-0 snap-center">
      <div className="px-5 xl:px-8 py-4 lg:py-6">
        <Skeleton height={16} width={width} />
      </div>
    </div>
  )
}

export default function SearchNavLoading() {
  return (
    <div className="drop-shadow-sm-bottom bg-white">
      <div className="container flex flex-row flex-nowrap overflow-x-auto overflow-y-hidden snap-x snap-mandatory justify-start items-center">
        {[...Array(4)].map((_, i) => (
          <NavLinkLoading width={WIDTHS[i]} key={i} />
        ))}
      </div>
    </div>
  )
}
