'use client'

import { Container, Icon, Typography } from '@shc/ui'
import { searchInputDebounceMs } from '@lib/constants'
import TextField from '@components/text-field'
import { useState, type ChangeEvent, type ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import { useSearchBox } from 'react-instantsearch'
import { useSearchParams } from 'next/navigation'
import { debounceQueryHook } from '@lib/search'
import SearchLocationAutocomplete from '@app/(main)/search/_components/search-location-autocomplete'

export interface SearchHeroProps extends ComponentPropsWithoutRef<'div'> {
  title?: string
  searchPlaceholderText: string
  disableLocationSearch?: boolean
}

const queryHook = debounceQueryHook(searchInputDebounceMs)

const SearchHero = ({
  title,
  searchPlaceholderText,
  disableLocationSearch = false,
}: SearchHeroProps) => {
  const searchParams = useSearchParams()
  const [queryValue, setQueryValue] = useState<string>(searchParams?.get('q') ?? '')
  const searchBox = useSearchBox({ queryHook })

  /* Handler */
  const handleQueryValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const q = event.target.value
    setQueryValue(q)
    searchBox.refine(q)
  }

  return (
    <div className="bg-primary-50">
      <Container
        className={clsx(
          'relative',
          'grid grid-cols-12 col-span-12 xl:col-span-10',
          'py-6 md:py-8 lg:py-15 gap-4 md:gap-8'
        )}>
        <div className="col-span-12 xl:col-span-8">
          <Typography variant="h1">{title}</Typography>
        </div>
        {/* search form fields */}
        <div className="col-span-12 md:col-span-6 xl:col-span-6">
          <TextField
            type="search"
            value={queryValue}
            onChange={handleQueryValueChange}
            autoFocus={true}
            autoComplete="off"
            autoCorrect="off"
            spellCheck={false}
            name="q"
            label="Search term"
            hideLabel={true}
            startAdornment={<Icon className="h-3.5" icon="magnifying-glass" />}
            placeholder={searchPlaceholderText}
          />
        </div>

        <div className={clsx('col-span-12 md:col-span-6 xl:col-span-4')}>
          <SearchLocationAutocomplete disabled={disableLocationSearch} />
        </div>
      </Container>
    </div>
  )
}
export default SearchHero
