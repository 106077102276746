import { Container, Skeleton } from '@shc/ui'

export const SearchHeroLoading = () => (
  <div className="bg-gray-100">
    <Container className="grid grid-cols-12 col-span-12 xl:col-span-10 py-6 md:py-8 lg:py-15 gap-4 md:gap-10">
      <div className="col-span-12 xl:col-span-8">
        <Skeleton height={36} width={100} className="bg-white" />
      </div>
      {/* search form fields */}
      <div className="col-span-12 md:col-span-6 xl:col-span-6">
        <Skeleton height={60} className="bg-white w-full" />
      </div>

      <div className="col-span-12 md:col-span-6 xl:col-span-4">
        <Skeleton height={60} className="bg-white w-full" />
      </div>
    </Container>
  </div>
)

export default SearchHeroLoading
