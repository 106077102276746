'use client'

import { useEffect, useState } from 'react'
import { useInstantSearch } from 'react-instantsearch'
import { Typography } from '@shc/ui'
import { useSharedSearchContext } from '@app/(main)/search/_contexts/search-context-wrapper'
import useGeocode from '@hooks/useGeocode'

const SearchResultsSummary = ({ isLocationDisplayed = true }) => {
  const {
    results: { index, nbHits, aroundLatLng },
  } = useInstantSearch()
  const { selectedLocation } = useSharedSearchContext()
  const [formattedLocation, setFormattedLocation] = useState<string | undefined>()
  const { reverseGeocode, isLoaded } = useGeocode()

  // Set the 'NEAR' location label to display after the results count.
  // - If `selectedLocation.formattedAddress` exists, use it.
  // - Otherwise, perform a reverse geocode lookup using `results.aroundLatLng`.
  // The reverse lookup is necessary when Algolia is called with {aroundLatLngViaIP: true}.
  useEffect(() => {
    const getFormattedLocation = async () => {
      let location
      if (nbHits && isLoaded) {
        if (selectedLocation) {
          location = selectedLocation.formattedAddress
        } else if (aroundLatLng) {
          const [lat, lng] = aroundLatLng.split(',').map(Number)
          try {
            const friendlyLocation = await reverseGeocode({ lat, lng })
            location = friendlyLocation[0]?.formattedAddress
          } catch (error) {
            console.error('Error fetching friendlyLocation:', error)
          }
        }
      }
      setFormattedLocation(location)
    }
    getFormattedLocation()
  }, [nbHits, aroundLatLng, isLoaded, selectedLocation, reverseGeocode])

  if (!nbHits) {
    return null
  }

  return (
    <div>
      {/* IMPORTANT: The "data-" attributes in the below span are important.
       They are used by the chatAnalytics component to send to Snowplow */}
      <span
        className="font-bold"
        id="searchResultCount"
        data-searchindex={index}
        data-nbhits={nbHits}>
        {nbHits.toLocaleString()}
      </span>{' '}
      {nbHits === 1 ? 'result' : 'results'}
      {isLocationDisplayed && formattedLocation && (
        <>
          {' '}
          near <Typography className="font-bold inline">{formattedLocation}</Typography>
        </>
      )}
    </div>
  )
}

export default SearchResultsSummary
