import { useEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { useInstantSearch } from 'react-instantsearch'
import useAnalytics, { type SearchQueryEvent } from '@hooks/use-analytics'
import { useBuildSearchContext } from '@lib/analytics'

// Send data to Snowplow when the search query changes
const SearchAnalyticsTracker = () => {
  const { results, uiState } = useInstantSearch()
  const { track } = useAnalytics()
  const searchParams = useSearchParams()
  const locationFieldValue = searchParams?.get('l')?.trim() ?? ''
  const snowplowSearchContext = useBuildSearchContext()
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [queryPlusLocation, setQueryPlusLocation] = useState('')

  // uiState comes back with named index keys, the first one has the query value
  const getQueryFromUiState = (): any => {
    const firstKey = Object.keys(uiState)?.[0]
    return firstKey ? uiState[firstKey]?.query : ''
  }

  // track search query
  useEffect(() => {
    const proposedQueryPlusLocation = `${getQueryFromUiState()}+${locationFieldValue}`

    // only track if the query has changed and we have the necessary information
    if (
      !results.__isArtificial && // ignore the initial load - do we need this?
      snowplowSearchContext && // do we need this?
      proposedQueryPlusLocation !== queryPlusLocation
    ) {
      setQueryPlusLocation(proposedQueryPlusLocation)

      let triggerType: SearchQueryEvent['search_query_trigger'] = 'onKeyUp'

      // initial load uses onSubmit, subsequent changes use onKeyUp
      if (isInitialLoad) {
        triggerType = 'onSubmit'
        setIsInitialLoad(false)
      }

      track({
        event: {
          name: 'search_query',
          data: {
            search_query_trigger: triggerType,
          },
        },
        contexts: [snowplowSearchContext ?? {}],
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results.hits])

  return null
}

export default SearchAnalyticsTracker
